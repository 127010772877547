define("discourse/plugins/discourse-ai/discourse/connectors/after-d-editor/ai-composer-helper", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/service", "ember-modifier", "discourse/lib/utilities", "discourse-common/config/environment", "discourse-common/utils/decorators", "discourse/plugins/discourse-ai/discourse/components/ai-composer-helper-menu", "discourse/plugins/discourse-ai/discourse/lib/show-ai-helper", "discourse/plugins/discourse-ai/discourse/lib/virtual-element-from-caret-coords", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _service, _emberModifier, _utilities, _environment, _decorators, _aiComposerHelperMenu, _showAiHelper, _virtualElementFromCaretCoords, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class AiComposerHelper extends _component.default {
    static shouldRender(outletArgs1, helper1) {
      return (0, _showAiHelper.showComposerAIHelper)(outletArgs1, helper1, "context_menu");
    }
    static #_ = (() => dt7948.g(this.prototype, "menu", [_service.inject]))();
    #menu = (() => (dt7948.i(this, "menu"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "aiComposerHelper", [_service.inject]))();
    #aiComposerHelper = (() => (dt7948.i(this, "aiComposerHelper"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "caretCoords", [_tracking.tracked]))();
    #caretCoords = (() => (dt7948.i(this, "caretCoords"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "menuPlacement", [_tracking.tracked], function () {
      return "bottom-start";
    }))();
    #menuPlacement = (() => (dt7948.i(this, "menuPlacement"), void 0))();
    static #_5 = (() => dt7948.g(this.prototype, "menuOffset", [_tracking.tracked], function () {
      return [9, 21];
    }))();
    #menuOffset = (() => (dt7948.i(this, "menuOffset"), void 0))();
    static #_6 = (() => dt7948.g(this.prototype, "selectedText", [_tracking.tracked], function () {
      return "";
    }))();
    #selectedText = (() => (dt7948.i(this, "selectedText"), void 0))();
    static #_7 = (() => dt7948.g(this.prototype, "isSelecting", [_tracking.tracked], function () {
      return false;
    }))();
    #isSelecting = (() => (dt7948.i(this, "isSelecting"), void 0))();
    static #_8 = (() => dt7948.g(this.prototype, "menuElement", [_tracking.tracked], function () {
      return null;
    }))();
    #menuElement = (() => (dt7948.i(this, "menuElement"), void 0))();
    static #_9 = (() => dt7948.g(this.prototype, "menuInstance", [_tracking.tracked], function () {
      return null;
    }))();
    #menuInstance = (() => (dt7948.i(this, "menuInstance"), void 0))();
    static #_10 = (() => dt7948.g(this.prototype, "dEditorInput", [_tracking.tracked]))();
    #dEditorInput = (() => (dt7948.i(this, "dEditorInput"), void 0))();
    static #_11 = (() => dt7948.g(this.prototype, "selectionRange", [_tracking.tracked], function () {
      return {
        x: 0,
        y: 0
      };
    }))();
    #selectionRange = (() => (dt7948.i(this, "selectionRange"), void 0))();
    minSelectionChars = 3;
    documentListeners = (() => (0, _emberModifier.modifier)(() => {
      document.addEventListener("mousedown", this.onMouseDown, {
        passive: true
      });
      document.addEventListener("mouseup", this.onMouseUp, {
        passive: true
      });
      document.addEventListener("selectionchange", this.onSelectionChanged);
      this.dEditorInput = document.querySelector(".d-editor-input");
      if (this.dEditorInput) {
        this.dEditorInput.addEventListener("scroll", this.updatePosition);
      }
      return () => {
        document.removeEventListener("mousedown", this.onMouseDown);
        document.removeEventListener("mouseup", this.onMouseUp);
        document.removeEventListener("selectionchange", this.onSelectionChanged);
        if (this.dEditorInput) {
          this.dEditorInput.removeEventListener("scroll", this.updatePosition);
        }
      };
    }))();
    willDestroy() {
      super.willDestroy(...arguments);
      this.menuInstance?.close();
    }
    onSelectionChanged() {
      if (this.aiComposerHelper.menuState !== this.aiComposerHelper.MENU_STATES.triggers) {
        return;
      }
      if (this.isSelecting) {
        return;
      }
      if (document.activeElement !== this.dEditorInput) {
        return;
      }
      const canSelect1 = Boolean(window.getSelection() && document.activeElement && document.activeElement.value);
      this.selectedText = canSelect1 ? document.activeElement.value.substring(document.activeElement.selectionStart, document.activeElement.selectionEnd) : "";
      this.selectionRange = canSelect1 ? {
        x: document.activeElement.selectionStart,
        y: document.activeElement.selectionEnd
      } : {
        x: 0,
        y: 0
      };
      if (this.selectedText?.length === 0) {
        this.menuInstance?.close();
        return;
      }
      if (this.selectedText?.length < this.minSelectionChars) {
        return;
      }
      this.selectionChanged();
    }
    static #_12 = (() => dt7948.n(this.prototype, "onSelectionChanged", [_decorators.bind]))();
    selectionChanged() {
      this.positionMenu();
    }
    static #_13 = (() => dt7948.n(this.prototype, "selectionChanged", [(0, _decorators.debounce)(_environment.INPUT_DELAY)]))();
    onMouseDown() {
      this.isSelecting = true;
    }
    static #_14 = (() => dt7948.n(this.prototype, "onMouseDown", [_decorators.bind]))();
    onMouseUp() {
      this.isSelecting = false;
      this.onSelectionChanged();
    }
    static #_15 = (() => dt7948.n(this.prototype, "onMouseUp", [_decorators.bind]))();
    updatePosition() {
      if (!this.menuInstance) {
        return;
      }
      this.positionMenu();
    }
    static #_16 = (() => dt7948.n(this.prototype, "updatePosition", [_decorators.bind]))();
    async positionMenu() {
      this.caretCoords = (0, _utilities.getCaretPosition)(this.dEditorInput, {
        pos: (0, _utilities.caretPosition)(this.dEditorInput)
      });
      const virtualElement1 = (0, _virtualElementFromCaretCoords.default)(this.caretCoords, this.menuOffset);
      if (this.handleBoundaries(virtualElement1)) {
        return;
      }
      // Position context menu at based on if interfering with button bar
      this.menuInstance = await this.menu.show(virtualElement1, {
        identifier: "ai-composer-helper-menu",
        placement: this.menuPlacement,
        component: _aiComposerHelperMenu.default,
        inline: true,
        modalForMobile: false,
        data: {
          selectedText: this.selectedText,
          dEditorInput: this.dEditorInput,
          selectionRange: this.selectionRange
        },
        interactive: true,
        onClose: () => {
          this.aiComposerHelper.menuState = this.aiComposerHelper.MENU_STATES.triggers;
        }
      });
      this.menuElement = this.menuInstance.content;
    }
    static #_17 = (() => dt7948.n(this.prototype, "positionMenu", [_decorators.afterRender]))();
    handleBoundaries(virtualElement1) {
      const textAreaWrapper1 = document.querySelector(".d-editor-textarea-wrapper").getBoundingClientRect();
      const buttonBar1 = document.querySelector(".d-editor-button-bar").getBoundingClientRect();
      const boundaryElement1 = {
        top: buttonBar1.bottom,
        bottom: textAreaWrapper1.bottom
      };
      const menuHeightBuffer1 = 35; // rough estimate of menu height since we can't get actual in this context.
      if (this.caretCoords.y - menuHeightBuffer1 < boundaryElement1.top) {
        this.menuPlacement = "bottom-start";
      } else {
        this.menuPlacement = "top-start";
      }
      if (this.isScrolledOutOfBounds(boundaryElement1, virtualElement1)) {
        this.menuInstance?.close();
        return true;
      }
    }
    isScrolledOutOfBounds(boundaryElement1, virtualElement1) {
      // Hide context menu if it's scrolled out of bounds:
      if (virtualElement1.rect.top < boundaryElement1.top) {
        return true;
      } else if (virtualElement1.rect.bottom > boundaryElement1.bottom) {
        return true;
      }
      return false;
    }
    static #_18 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{this.documentListeners}}></div>
      
    */
    {
      "id": "KK/Tjh1o",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[30,0,[\"documentListeners\"]],null,null],[12],[13],[1,\"\\n  \"]],[],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/after-d-editor/ai-composer-helper.js",
      "isStrictMode": true
    }), this))();
  }
  _exports.default = AiComposerHelper;
});